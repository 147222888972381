<template>
  <div class="content">
    <el-tabs v-model="activeName" @tab-click="handleClick" style="margin:20px 0 0 30px">
        <el-tab-pane :label="'参与活动 ('+this.myActivitiesCount+')'" name="1"></el-tab-pane>
        <el-tab-pane :label="'他关注的 ('+this.myFocusCount+')'" name="2"></el-tab-pane>
        <el-tab-pane :label="'关注他的 ('+this.payAttentionToMyCount+')'" name="3"></el-tab-pane>
        <el-tab-pane :label="'参与项目 ('+this.myProjectCount+')'" name="4"></el-tab-pane>
    </el-tabs>
    <!-- 参与活动 -->
    <div class="item_line" v-if="activeName == 1" >
      <div class="itemList" v-for="(item,index) in getActivityList" :key="index">
        <div class="item">
            <div class="goods_img">
                <img :src="item.activityCover">
            </div>
        </div>
        <div class="item_text">
            <span>{{item.activityName}}</span>
            <div>
                <div>
                    <i class="el-icon-office-building"></i>
                    企业: {{item.companyName}}
                </div>
                <div>
                    <i class="el-icon-time"></i>
                    时间: {{item.startTime}}-{{item.endTime}}
                </div>
                <div>
                    <i class="el-icon-time"></i>
                    金额: {{item.price}} 元/人
                </div>
                <div>
                    <i class="el-icon-location-outline"></i>
                    地点: {{item.activityAddress}}
                </div>
            </div>
            <div class="bottom">
                <div style="margin-right:30px">成功报名: {{item.successCount}}</div>
                <div class="baoMing">
                  <div class="imgIcon">
                      <img src="../../../../../static/images/jr-icon-sign 1.png" >
                  </div>
                  <div @click="handleManage(item)" class="handle-item">报名管理</div>
                </div>
            </div>
        </div>
        <div class="btn acting" v-if="item.isSued ==='进行中'">进行中</div>
        <div class="btn over" v-else>已结束</div>
      </div>
    </div>
    <div v-if="this.whetherMember == 1">
      <!-- 他关注的和关注他的 -->
      <div class="item_line" v-if="activeName == 2 || activeName == 3">
        <el-table :data="getAttentionList" style="width: 100%" class="data_list" v-loading="loading" :header-cell-style="getRowClass">
          <el-table-column label="用户ID" prop="id" align="center" width="80" />
          <el-table-column label="头像" width="200" align="center">
            <template v-slot="{ row }">
              <div class="flex">
                <img style="width:78px"  :src="row.headPortrait"/>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="真实姓名" prop="userName" align="center" width="80" />
          <el-table-column label="手机号" prop="mobile" align="center" width="120" />
          <el-table-column label="机构" prop="institution" align="center" width="100" />
          <el-table-column label="职位" prop="position" align="center" width="80" />
          <el-table-column label="标签" align="center" width="300">
            <template v-slot="{ row }">
              <div class="tip_two">
                <div v-for="(item,index) in row.labelList" :key="index">{{item}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="用户等级" prop="whetherMember" align="center" width="100" />
          <el-table-column label="用户简介" align="center" prop="personalIntro" :show-overflow-tooltip="true"/>
        </el-table>
      </div>
      <!-- 参与项目 -->
      <div class="item_line" v-if="activeName == 4">
        <el-table :data="getProjectList" style="width: 100%" class="data_list" v-loading="loading" :header-cell-style="getRowClass">
          <el-table-column type="index" :index="1" label="序号" sortable width="80" align="center"/>
          <el-table-column label="项目名称" prop="projectName" align="center" width="80" />
          <el-table-column label="项目状态" width="100" align="center" prop="projectState">
            <!-- <template v-slot="{ row }">
              <div class="flex">
              </div>
            </template> -->
          </el-table-column>
          <el-table-column label="参与会员" prop="participationMember" align="center" width="80" />
          <el-table-column label="中间人" prop="intermediary" align="center" width="80" />
          <el-table-column label="项目负责人" prop="createName" align="center" width="100" />
          <el-table-column label="地点" prop="phone" align="center" width="80">
            <template v-slot="{ row }">
              {{row.country}}{{row.province}}{{row.city}}
            </template>
          </el-table-column>
          <el-table-column label="项目开始时间" prop="projectStartTime" align="center" width="120" />
          <el-table-column label="项目结束时间" prop="projectEndTime" align="center" width="120" />
          <el-table-column label="项目描述" prop="projectIntro" align="center" width="300" />
          <el-table-column label="操作" align="center" >
            <template>
              <div class="color_one" @click="nothingReally()">
                查看详情
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-else>
      <div class="item_line" v-if="activeName == 2 || activeName == 3 || activeName == 4">
        <div class="nothing">
          <img src="../../../../../static/images/404.png">
        </div>
      </div>
    </div>
    <div class="new_page" v-if="this.whetherMember = 1">
      <el-pagination
        :current-page="queryInfo.current"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>


<script>
import { pagination } from '@/mixins/pagination'
import { getActivity, getAttention, getBeAttention, getProject, getAllCount } from '@/api/userManagement.js'
const defaultQueryInfo = Object.freeze({
  current: 0,
  size: 10,
})
export default {
  mixins: [pagination],
  data() {
      return {
        activeName: '1',
        flag:false,
        loading:false,
        queryInfo: { ...defaultQueryInfo },
        getActivityList:[],//参与活动
        getProjectList:[],//参与项目
        getAttentionList:[],//他关注的和关注他的
        myActivitiesCount: '',
        myFocusCount: '',
        myProjectCount: '',
        payAttentionToMyCount: '',
        whetherMember: '',
      };
    },
    mounted(){
      // console.log(this.$route.query,"123");
      this.activeName = this.$route.query.typeNum
      this.queryInfo.id = this.$route.query.id
      this.whetherMember = this.$route.query.whetherMember
      this.getAllCount(this.queryInfo.id)
      this.switchTab(this.activeName)
    },
    methods: {
      handleClick(tab) {
        this.activeName = tab.name
        this.switchTab(this.activeName)
      },
      search(){
        this.switchTab(this.activeName)
      },
      switchTab(activeName) {
        if(activeName == '1') this.getActivity(this.queryInfo)
        else if(activeName == '2') {
          this.getAttentionList = []
          this.getAttention(this.queryInfo)
        }
        else if(activeName == '3') {
          this.getAttentionList = []
          this.getBeAttention(this.queryInfo)
        }
        else if(activeName == '4') this.getProject(this.queryInfo)
      },
      async getAllCount(id) {
        const { data:res } = await getAllCount({id:id})
        this.myActivitiesCount = res.data.myActivitiesCount
        this.myFocusCount = res.data.myFocusCount
        this.myProjectCount = res.data.myProjectCount
        this.payAttentionToMyCount = res.data.payAttentionToMyCount
      },
      async getActivity(queryInfo) {
        const { data:res } = await getActivity(queryInfo)
        if( res.data == null ){
          this.$message.error(res.message)
          this.loading = false
          return false
        }
        this.getActivityList = res.data.list
        this.total = res.data.total
      },
      async getAttention(queryInfo) {
        if(this.whetherMember != 1) return false
        this.loading = true
        const { data:res } = await getAttention(queryInfo)
        if( res.data == null ){
          this.$message.error(res.message)
          this.loading = false
          return false
        }
        this.getAttentionList = res.data.list
        this.total = res.data.total
        this.loading = false
      },
      async getBeAttention(queryInfo) {
        if(this.whetherMember != 1) return false
        this.loading = true
        const { data:res } = await getBeAttention(queryInfo)
        if( res.data == null ){
          this.$message.error(res.message)
          this.loading = false
          return false
        }
        this.getAttentionList = res.data.list
        this.total = res.data.total
        this.loading = false
      },
      async getProject(queryInfo) {
        if(this.whetherMember != 1) return false
        this.loading = true
        const { data:res } = await getProject(queryInfo)
        if( res.data == null ){
          this.$message.error(res.message)
          this.loading = false
          return false
        }
        this.getProjectList = res.data.list
        this.total = res.data.total
        // console.log(this.total,"total");
        this.loading = false
      },
      nothingReally() {
        this.$message('抱歉！该模块正在开发中！')
      },
       handleManage(row) {
      this.$router.push({
        name: "manageList",
        query: { id: row.id }
      })
    },
    }
}
</script> 

<style scoped lang="less">
.content{
    height: auto !important;
    background: #FFFFFF;
    border-radius: 12px;
    margin: 15px 0px 0px 20px;
    overflow: hidden;
    .item_line{
      margin: 0px 0 0 20px;
      .itemList{
        position: relative;
        display: flex;
        border-bottom: 0.5px solid #C4C4C4;
        .item{
          .goods_img{
            width: 290px;
            height: 150px;
            background: #C4C4C4;
            border-radius: 8px;
            margin: 16px 16px 16px 0;
            overflow: hidden;
            img{
              width: 100%;
              height: 100%;
            }
          }
        }
        .item_text{
          margin-top: 16px;
          span{
            font-size: 18px;
          }
          div{
            margin-top: 5px;
            font-size: 14px;
            .icon-jr-icon-sign{
              
            }
          }
          .bottom{
            display: flex;
            .baoMing{
              display: flex;
              margin-top: 0px;
              .imgIcon{
                width: 18px;
                height: 18px;
                margin-right: 5px;
                img{
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
        .btn{
          position: absolute;
          width: 78px;
          height: 32px;
          background: #FE6C41;
          border-radius: 4px;
          text-align: center;
          line-height: 32px;
          right: 80px;
          top: 60px;
        }
        .acting{
          cursor: pointer;
        }
        .over{
          background: #9B9B9B;
        }
      }
      .nothing{
        width: 100%;
        height: 600px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 240px;
          height: 260px;
        }
      }
    }
    .color_one{
      color:#7C71E1;
      cursor: pointer;
    }
  .new_page {
    width: 100%;
    text-align: center;
    margin: 30px auto;
  }
}
.tip_two{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 8px;
  div{
    padding: 2px 5px;
    margin-top: 5px;
    font-size: 12px;
    line-height: 17px;
    color: #7067F2;
    background: #7067F21A;
    margin-right: 8px;
  }
 
}
.handle-item{
  font-size: 14px;
  cursor: pointer;
}

</style>
